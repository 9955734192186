.review-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.review-header {
    background-color: #f5f5f5;
    padding: 5px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    flex-shrink: 0;
}

.score-section {
    text-align: center;
    padding: 10px 15px;
    background-color: #f5f7fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 15px auto;
    max-width: 500px; /* Increased max-width for better layout */
}

.score-details {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Align items horizontally */
    gap: 15px; /* Add spacing between items */
    padding: 5px 0;
}

.score-title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.score-info {
    display: flex;
    align-items: center; /* Align items vertically center */
    gap: 20px; /* Space between accuracy and score */
}

.accuracy, .score {
    display: flex;
    align-items: center; /* Keep labels and values on the same line */
}

.label {
    font-size: 16px;
    color: #555;
}

.value {
    font-size: 14px;
    font-weight: bold;
    color: #4caf50;
}

.value.score {
    color: #2196f3;
}



.review-content {
    display: flex;
    flex: 1;
    overflow: hidden; /* Prevent scrolling of the entire page */
    padding-left: 20px; /* Add padding around the content */
    padding-right: 20px;
}

.question-list {
    width: 200px;
    height: 100vh;
    max-height: calc(100vh - 200px);
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Allow vertical scrolling */
    background-color: #fafafa;
    padding: 10px;
    margin-right: 20px; /* Add some space to the right of the question list */
    border-radius: 8px; /* Add border radius for card-like appearance */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow for card effect */
    flex-shrink: 0;
}

.question-list-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
}

.question-list-item.selected {
    background-color: #e0e0e0;
}

.question-list-item.correct {
    color: green;
}

.question-list-item.incorrect {
    color: red;
}

.question-details {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Prevent scrolling of the entire page */
}

.question-review-container {
    max-width: 1000px;
    max-height: calc(100vh - 250px);
    width: 100%;
    height: 100%; /* Ensure the container respects the parent's height */
    background-color: #fff; /* White background for card-like appearance */
    padding: 20px; /* Padding inside the card */
    border-radius: 8px; /* Rounded corners for card-like appearance */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Shadow for card effect */
    overflow-y: auto; /* Allow vertical scrolling if content is long */
}

.section {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.question-body {
    margin-bottom: 20px;
}

.answer-section {
    margin-top: 0px;
}

.answer-section > div {
    margin-bottom: 0px;
}

.correct {
    color: green;
    font-weight: bold;
}

.incorrect {
    color: red;
    font-weight: bold;
}

.question-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 20px 0;
}


.answer-correct-answer-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
}

.answer, .correct-answer {
    flex: 1;
    margin-right: 10px;
}

.question-header {
    display: flex;
    justify-content: space-between; /* This pushes the content to the sides */
    align-items: center; /* Vertically centers the content */
}

.question-header span {
    margin-left: auto; /* This ensures the span (and the button) stays on the right */
}


.report-error-button {
    margin-right: 0px;
    background: #dbdee1;
    color: #b0b3b8;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.report-error-button:hover {
    background-color: #b0b3b8;
    color: #fff;
}

/* Modal styling */
.report-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.report-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative; /* Make it relative so the close button can be absolutely positioned */
    display: flex;
    flex-direction: column;
    gap: 15px;
}


.close-button:hover {
    color: #d32f2f;
}

/* Textarea styling */
.report-modal-content textarea {
    width: 95%;
    height: 100px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 14px;
    resize: none;
}

/* Button container */
.report-modal-content .button-container button {
    background-color: #1e3a8a;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    margin-right: 10px;
}

.report-modal-content .button-container button:hover {
    background-color: #143e76;
}

/* Align buttons horizontally */
.report-modal-content .button-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.close-button {
    background-color: transparent;
    color:#333;
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}