.exam-page {
    padding-top: 10px;
}

.exam-container {
    position: relative;
    padding: 10px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    min-height: 70vh; /* Adjust height to fit content */
    overflow: hidden; /* Prevent horizontal scrolling */
}

.question-index {
    text-align: right;
    top: 20px;
    padding-top: 10px;
    margin-right: 20px;
    font-size: 18px;
    font-weight: bold;
}

.flag-button {
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 10px;
}

.flag-button:hover {
    background-color: #0056b3;
}

.question-container {
    text-align: left;
    height: 60vh;
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    background: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin: 10px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.question-header {
    margin-bottom: 20px;
}

.question-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.left-section {
    display: flex;
    align-items: center;
}


.calculator-toggle {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #333;
    margin-right: 10px; /* Adds space between calculator and flag button */
    background: none;
}
.calculator-toggle:hover {
    background-color: #f0f0f0; /* Add a subtle background color on hover */
    color: inherit; /* Keep the original color for the icon */
}

.flag-button {
    margin-left: 10px;
}


.question-body {
    margin-bottom: 20px;
}

.question-image {
    max-width: 100%; /* Ensure the image does not exceed the container's width */
    max-height: 200px; /* Set the maximum height */
}

.selections-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.selections-list li {
    margin: 10px 0;
    display: flex;
    align-items: center;
}

.selections-list input[type="radio"] {
    margin-right: 10px;
}

.navigation-buttons {
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: flex;
    gap: 10px;
}

button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #1e5088;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* button:hover:not(:disabled) {
    background-color: #0056b3;
} */

button:active:not(:disabled) {
    transform: scale(0.98);
    /* background-color: #004494; */
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 50%;
    max-height: 80%;
    overflow-y: auto;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.modal-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.modal-table th, .modal-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.modal-table th {
    background-color: #007bff;
    color: white;
}

.modal-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.modal-table tr:hover {
    background-color: #ddd;
}

.modal-table tr.selected {
    background-color: #b3d7ff;
}

.modal-table td {
    cursor: pointer;
}

.modal-table .status-incomplete {
    color: red;
}

.modal-table .status-unseen {
    color: gray;
}

.modal-table .status-complete {
    color: green;
}

.modal-table .flagged {
    background-color: yellow;
}

.close-button {
    margin-top: 10px;
    cursor: pointer;
}

/* Confirmation Modal Enhancements */
.confirmation-modal-content {
    text-align: center;
    padding: 20px;
}

.confirmation-modal-content h2 {
    margin-bottom: 20px;
    color: #333;
}

.confirmation-modal-content p {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.confirm-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.confirm-buttons button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #1e5088;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.confirm-buttons button:hover {
    background-color: #0056b3;
}

.confirm-buttons button:active {
    transform: scale(0.98);
    background-color: #004494;
}

.confirm-buttons button:last-child {
    background-color: #888;
}

.confirm-buttons button:last-child:hover {
    background-color: #555;
}

/* Enhanced Styling for Navigation Buttons */
.navigation-buttons button {
    font-size: 18px;
    border-radius: 8px;
    background-color: #28a745;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navigation-buttons button:hover:not(:disabled) {
    background-color: #218838;
}

.navigation-buttons button:active:not(:disabled) {
    transform: translateY(2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navigation-buttons button:disabled {
    background-color: #6c757d;
}

.navigation-buttons .navigator-button {
    background-color: #007bff;
}

.navigation-buttons .navigator-button:hover {
    background-color: #0069d9;
}

.navigation-buttons .navigator-button:active {
    background-color: #0056b3;
}

.draggable-calculator {
    position: absolute;
    z-index: 1000;
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 10px;
    cursor: move; /* Show move cursor */
    max-width: 600px; /* Set a maximum width for the calculator */
}