/* RemindPurchaseModal.css */
.remind-purchase-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    width: 420px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    outline: none;
    display: flex;
    flex-direction: column;
    z-index: 1001; /* Keep this high to ensure it's above the overlay */
}

.remind-purchase-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Ensure semi-transparent black background */
    z-index: 1000; /* Just below the modal */
    pointer-events: auto; /* Ensure that clicks are blocked outside the modal */
}

/* Modal header and close button */
.modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -20px;
}

/* Modal content */
.modal-content p {
    font-size: 16px;
    color: #333;
}

/* Modal actions (buttons) */
.modal-actions {
    display: flex;
    justify-content: space-between;
}

.action-button {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.upgrade-button {
    background-color: #007BFF;
    color: white;
    border: none;
    transition: background-color 0.3s ease;
}

.upgrade-button:hover {
    background-color: #0056b3;
}

.cancel-button {
    background-color: #6c757d;
    color: white;
    border: none;
    transition: background-color 0.3s ease;
}

.cancel-button:hover {
    background-color: #5a6268;
}
