.exam-header {
  width: 100%;
  padding: 20px 20px;
  margin-top: -10px;
  background-color: #333;
  color: white;
}

.header-content {
  display: flex;
  justify-content: space-between; /* Space between left and right content */
  align-items: center;
}

.left-content h1 {
  margin: 0;
  font-size: 24px;
}

.right-content {
  display: flex;
  align-items: center;
  gap: 15px; /* Space between time remaining and question progress */
}

.time-remaining, .question-progress {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding-right: 30px;
}

.time-remaining .fa-clock, .question-progress .fa-file {
  margin-right: 5px; /* Space between icon and text */
}


.exam-header .home-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  margin-right: 20px;
}

/* Clear any unwanted styles on hover */
.exam-header .home-button:hover {
  color: #ccc; /* or any color you prefer */
  background: none; /* Ensure no background appears */
  transition: color 0.2s ease-in-out; /* Smooth transition */
}

