/* paymentStatus.css */
.payment-status-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f7f9fc;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    padding: 20px;
  }
  
  .payment-status-card {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    text-align: center;
  }
  
  .payment-status-card h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .payment-status-card p {
    font-size: 1.2rem;
    color: #555;
    margin: 10px 0;
  }
  
  .payment-status-card .status-success {
    color: #4caf50;
    font-weight: bold;
  }
  
  .payment-status-card .status-failed {
    color: #f44336;
    font-weight: bold;
  }
  
  .payment-status-card .status-unknown {
    color: #ff9800;
    font-weight: bold;
  }
  
  .payment-status-card .formal-thanks {
    margin-top: 20px;
    font-size: 1rem;
    color: #777;
  }
  
  .payment-status-card a {
    color: #007bff;
    text-decoration: none;
  }
  
  .payment-status-card a:hover {
    text-decoration: underline;
  }
  