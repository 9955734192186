.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
}

.login-container form {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 300px;
}

.login-container h2 {
    margin-bottom: 1rem;
    text-align: center;
}

.login-container input {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-container button {
    padding: 0.5rem;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-container button:hover {
    background-color: #2980b9;
}

.login-container p {
    text-align: center;
    color: #3498db;
    cursor: pointer;
    margin-top: 1rem;
}

.login-container p:hover {
    text-decoration: underline;
}


.error-message-bar {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px; /* Adjusted to ensure space from the paragraph */
    text-align: center;
}
