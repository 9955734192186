/* Base header styling */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #4b536a;
    padding: 10px 20px;
    color: white;
    position: fixed; /* Fixed header */
    top: 0;
    width: 100%; /* Full width */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

/* Logo styling */
.logo {
    display: flex;
    align-items: center;
}

.logo-img {
    width: 150px; /* Adjust size as needed */
    max-height: 50px; /* Prevent increasing header height */
    height: auto;
}

/* Navigation container styling */
.nav-container {
    flex: 1;
    display: flex;
    justify-content: center;
}

nav ul {
    list-style: none;
    display: flex;
    gap: 20px; /* Gap between navigation items */
}

nav li {
    display: inline;
}

nav a {
    color: white;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 1em;
}

nav a:hover {
    background-color: #1d5876;
    color: #f0f0f0;
}

/* User actions styling */
.user-actions {
    display: flex;
    align-items: center;
    gap: 15px; /* Gap between user name and logout button */
}

.logout-button, .login-button {
    background-color: #84888e; /* Distinct background color */
    border: none;
    color: white;
    padding: 10px 15px;
    border-radius: 20px; /* Rounded button */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    font-size: 0.9em; /* Adjusted size */
    text-decoration: none;
    margin-right: 25px;
}

.logout-button:hover, .login-button:hover {
    background-color: #3370df; /* Darker shade on hover */
    transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Media queries for responsive design */
@media (max-width: 768px) {
    .header {
        flex-direction: row; /* Keep items in a row */
        padding: 5px 10px; /* Reduce padding */
        justify-content: space-between; /* Space out items */
        height: auto;
    }

    .logo {
        margin-right: auto; /* Push logo to the left */
    }

    .logo-img {
        width: 100px; /* Smaller logo size */
        max-height: 40px;
    }

    .nav-container {
        flex-direction: column; /* Stack items when shown */
        width: 100%; /* Full width */
        padding-top: 10px;
        align-items: center;
    }

    .nav-active .nav-container {
        display: flex; /* Show nav on active */
    }

    .nav-container a {
        font-size: 0.9em;
    }

    .nav-container ul {
        margin: 0;
        padding: 0px;
    }

    nav li {
        display: flex; /* Use flex for proper alignment */
        flex-direction: column; /* Stack text */
        align-items: center; /* Center items */
    }

    nav ul {
        list-style: none;
        display: flex;
        gap: 15px; /* Consistent gap between items */
        padding: 0; /* Remove padding */
        margin: 0; /* Remove margin */
    }

    .user-actions {
        flex-direction: row; /* Align buttons in a row */
        align-items: center;
        justify-content: flex-end; /* Align to the right */
        margin-left: 10px; /* Small gap */
        gap: 10px;
    }

    .logout-button, .login-button {
        font-size: 0.8em;
        padding: 5px 6px; /* Adjust padding for buttons */
    }
}