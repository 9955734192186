/* Basic reset */
body {
    font-family: 'Arial, sans-serif';
    background-color: #f5f5f5;
    margin: 0;
    box-sizing: border-box;
}

/* Homepage Container with a Pretty Background */
.homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px;
    min-height: 100vh;
    box-sizing: border-box;
    background: linear-gradient(135deg, #f0f9ff, #eaf7f4, #f0f9ff); /* Soft pastel gradient */
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite; /* Optional animation for a gentle flow */
}

/* Keyframes for the subtle gradient animation */
@keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

/* Hero Section with Unified Background */
.hero-section {
    background: none;
    color: #37474f;
    text-align: center;
    padding: 50px 20px;
    margin-bottom: 30px;
    border-radius: 15px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 1200px;
    margin-top: 40px;
}

.hero-section h2 {
    margin: 10px 0;
    font-size: 2em;
    font-weight: 300;
    color: #00796b;
}

.hero-section p {
    font-size: 1.1em;
    margin: 20px 0;
    line-height: 1.5;
    color: #37474f;
}

#exam-choices {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.exam-button {
    background: linear-gradient(135deg, #a0e1eb, #d3f8f2); /* Lighter gradient for a softer look */
    color: #004d40; /* Dark teal for contrast and readability */
    text-decoration: none;
    padding: 15px 30px;
    margin: 10px;
    border-radius: 12px;
    font-size: 1.1em;
    font-weight: bold;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
    border: none;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.exam-button:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
    background: linear-gradient(135deg, #d3f8f2, #a0e1eb); /* Reverse gradient on hover */
}

/* Unified Section */
.unified-section {
    background: none;
    padding: 50px 20px;
    border-radius: 15px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 1200px;
    margin-bottom: 30px;
    text-align: center;
}

/* Content Section inside Unified Section */
.content-section {
    text-align: center;
    margin: 0 auto 40px;
    padding: 0;
}

.content-section h3 {
    font-size: 1.8em;
    margin-bottom: 20px;
    color: #00796b;
}

.offer-list {
    list-style: none;
    padding: 0;
    text-align: left;
    margin: 0 auto;
    max-width: 700px;
}

.offer-list li {
    position: relative;
    padding: 10px 0 10px 35px;
    margin-bottom: 10px;
    font-size: 1em;
    line-height: 1.6;
    color: #37474f;
}

.icon {
    position: absolute;
    left: 0;
    top: 6px;
    font-size: 1.2em;
    color: #4caf50;
}

/* Testimonials Section inside Unified Section */
.testimonials-section {
    margin-top: 20px;
}

.testimonials-section h3 {
    font-size: 1.8em;
    margin-bottom: 30px;
    color: #00796b;
}

.testimonials-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 0 20px;
    flex-wrap: wrap;
}

.testimonial {
    background-color: #ffffff;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 1;
    margin: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-left: 5px solid #00796b;
}

.testimonial p {
    font-size: 1.1em;
    font-style: italic;
    color: #37474f;
}

.testimonial:hover {
    transform: scale(1.03);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
    .testimonials-container {
        flex-direction: column;
        align-items: center;
    }

    .testimonial {
        width: 100%;
        max-width: 500px;
    }

    .content-section {
        padding: 20px;
    }

    .offer-list li {
        padding-left: 30px;
    }
}

@media (max-width: 768px) {
    .homepage-container {
        padding-left: 20px;
        padding-right: 20px;
    }
}


.footer {
    text-align: center;
    padding: 10px 0;
    font-size: 10px;
    color: #888; 
}

.icp-number a {
    color: #888;
    text-decoration: none;
}

.icp-number a:hover {
    text-decoration: underline;
}