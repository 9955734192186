.review-page-container {
    padding: 20px;
    margin-top: 60px;
    font-family: Arial, sans-serif;
    background-color: #f0f2f5;
    min-height: 100vh;
    position: relative; /* Ensure it is properly positioned relative to other content */
    z-index: 0; /* Ensure it's not unintentionally covered by other elements */
}

.content-container {
    display: flex;
    justify-content: center; /* Center content */
}

.main-content {
    width: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navigation button {
    width: 100%;
    margin-bottom: 15px;
    padding: 15px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 1; /* Ensure buttons are clickable */
    position: relative;
}

.navigation button:hover {
    background-color: #0056b3;
}

.dropdown {
    width: 90%;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
    max-height: 0;
    padding: 0 10px;
    margin-bottom: 15px;
    transition: max-height 0.3s ease, padding 0.3s ease;
    z-index: 1; /* Ensure dropdown is clickable */
    position: relative;
}

.dropdown ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dropdown li {
    margin-bottom: 10px;
    cursor: pointer; /* Ensure cursor changes to pointer to indicate clickability */
    padding: 10px; /* Add padding to make the click area larger */
    background-color: #fff; /* Ensure the background is consistent */
    border-radius: 4px;
    position: relative; /* Ensure it is properly positioned relative to other content */
    z-index: 1; /* Ensure it's not unintentionally covered by other elements */
    transition: background-color 0.3s ease;
}

.dropdown li:hover {
    background-color: #f0f0f0; /* Add a hover effect to indicate the item can be clicked */
}

.dropdown li:last-child {
    margin-bottom: 0;
}

.dropdown a {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    transition: color 0.3s ease;
    display: block; /* Ensure the link takes up the full area of the list item */
}

.dropdown a:hover {
    color: #0056b3;
}

.dropdown.open {
    max-height: 200px; /* Adjust as necessary */
    padding: 10px;
    overflow-y: auto; /* Allow scrolling if content exceeds max-height */
    z-index: 1; /* Ensure dropdown content is above other content */
}
