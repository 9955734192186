.esat-introduction {
    padding: 20px;
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.intro-section {
    background-color: #4b536a; /* Dark background to match the FAQ items 0b1227*/
    color: #ffffff;
    padding: 20px 30px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: left; /* Left-align text inside the section */
}

.intro-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.intro-section h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    font-weight: bold;
}

.intro-divider {
    width: 100%;
    border: none;
    height: 2px;
    background-color: #1c6aaf; /* Match the primary color used in FAQ */
    margin: 0 auto 20px auto;
    border-radius: 2px;
}

.intro-section p {
    font-size: 1rem;
    margin-bottom: 15px;
    line-height: 1.6;
}

.intro-section ul {
    margin-bottom: 15px;
    padding-left: 20px;
    list-style-type: disc;
}

.intro-section ul li {
    margin-bottom: 10px;
    line-height: 1.5;
}

/* Button Style - if you want to include a button similar to the FAQ style */
.view-esat-guides-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #1c6aaf;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.view-esat-guides-button:hover {
    background-color: #0b1227;
    color: #1c6aaf;
}
