.faq-container {
    padding: 20px;
    margin: 0 auto;
    text-align: center;
}

.faq-title {
    font-size: 3rem;
    color: #1c6aaf;
    margin-bottom: 10px;
}

.faq-divider {
    width: 70%;
    margin: 0 auto 30px auto;
    border: none;
    height: 3px;
    background-color: #1c6aaf;
}

.faq-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.faq-item {
    background-color: #4b536a;
    color: #ffffff;
    padding: 15px 20px;
    width: 80%; /* Adjust width as needed */
    margin-bottom: 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: left;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.faq-item.active {
    background-color: #1c6aaf;
}

.faq-question {
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.faq-answer {
    font-size: 1.1rem;
    line-height: 1.5;
    overflow: hidden;
    transition: max-height 0.5s ease, padding 0.5s ease; /* Smooth transition */
}

.faq-arrow {
    margin-right: 10px;
    font-size: 1.2rem;
    transition: transform 0.3s ease;
}

.faq-item.active .faq-arrow {
    transform: rotate(90deg);
}

@media (max-width: 768px) {
    .faq-item {
        width: 100%; /* Stack items vertically on small screens */
    }
}
