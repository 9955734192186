.practice-page-container {
    display: flex;
    flex-direction: row; /* Align cards horizontally */
    flex-wrap: wrap; /* Allow cards to wrap to the next line if necessary */
    justify-content: center; /* Center the cards horizontally */
    gap: 20px;
    padding-top: 80px;
    font-family: 'Arial, sans-serif';
    overflow: hidden;
    z-index: 500;
}

.card {
    background: white;
    font-family: 'Playfair Display', serif;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 78%; /* Adjust the width to fit multiple cards in a row */
    min-height: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px; /* Add margin for spacing between cards */
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: white; /* Ensure text is visible on colored backgrounds */
}

.card-math .card-header {
    background: #FFD700; /* Yellow */
}

.card-physics .card-header {
    background: #FF4500; /* Orange */
}

.card-esat .card-header {
    background: #4b536a; /* Light Blue */
}

.card-pat .card-header {
    background: #4b536a; /* Light Green */
}

.card-icon {
    font-size: 1.5em;
    color: white;
}

.card-time {
    background: rgba(255, 255, 255, 0.8);
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-time i {
    margin-right: 5px; /* Add space between the icon and text */
}

.card-body {
    padding: 20px;
}

.card-body h2 {
    font-size: 1.8em;
    margin: 0;
    color: #333;
}

.card-body p {
    color: #666;
}

.card-footer {
    padding: 10px 20px;
    background: #f8f8f8;
    border-top: 1px solid #ddd;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.start-button {
    padding: 10px 20px;
    font-size: 1em;
    border: none;
    background-color: #4b536a;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.start-button:hover {
    background-color: #1d5876;
    transform: translateY(-3px);
}

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    width: 100%;
    text-align: center;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.Modal h3 {
    font-size: 1.5em;
    margin-bottom: 20px;
    margin-top: 0px;
    color: #333;
}

.Modal ul {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
    max-height: 500px;
    overflow-y: auto;
}

.Modal li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f5f5f5;
    margin: 5px 0;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
}

.Modal li:hover {
    background-color: #e0e0e0;
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.Modal li a {
    text-decoration: none;
    color: #333;
    display: block;
    width: 100%;
    height: 100%;
}

.exam-item.done {
    background-color: #e6f7e6;
}

.exam-item.undone {
    background-color: #fff0f0;
}

.exam-name {
    font-weight: bold;
}

.exam-status-container {
    display: flex;
    align-items: center;
}

.exam-status {
    font-size: 0.9em;
    padding: 2px 6px;
    border-radius: 3px;
    margin-left: 10px;
}

.exam-status.done {
    background-color: #4CAF50;
    color: white;
}

.exam-status.undone {
    background-color: #F44336;
    color: white;
}

.review-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s;
}

.review-button:hover {
    background-color: #45a049;
}

.centered-list li{
    justify-content: center;
}

.reset-button {
    background-color: #F44336;
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
    border-radius: 3px;
    margin-left: 10px;
    transition: background-color 0.3s;
}

.reset-button:hover {
    background-color: #d32f2f;
}

.confirm-modal{
    width: 325px;
}


.confirm-modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.confirm-button, .cancel-button {
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.confirm-button {
    background-color: #F44336;
    color: white;
}

.confirm-button {
    background-color: #F44336;
    color: white;
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px; /* Ensures the button maintains a consistent width */
    height: 40px; /* Adjust height to match spinner size */
}

.confirm-button:hover {
    background-color: #d32f2f;
}

.small-loading-spinner {
    border: 3px solid rgba(255, 255, 255, 0.5);
    border-top-color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-left: 5px;
}

.exam-type{
    max-width: 300px;
}


.unlock-text {
    font-weight: bold; /* Makes the text bold */
    color: #1d5876; /* Changes the text color, adjust as needed */
    margin-right: 5px; /* Adds some spacing between the text and the lock icon */
    cursor: pointer; /* Changes the cursor to a pointer to indicate interactivity */
    transition: color 0.3s ease; /* Adds a smooth transition effect */
}

.unlock-text:hover {
    color: #d32f2f; /* Changes color on hover for a subtle interactive effect */
}

.remind-purchase-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Ensure semi-transparent black background */
    z-index: 1000; /* Just below the modal */
    pointer-events: auto; /* Ensure that clicks are blocked outside the modal */
}