.service-info-container {
    padding: 50px;
    margin: 50px auto;
    text-align: center;
    color: #4b536a; /* Primary text color */
    max-width: 900px; /* Set a max-width for responsiveness */
    background-color: #f9f9f9; /* Light background for contrast */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.service-info-container h1 {
    font-size: 36px;
    color: #1e3c72; /* Primary dark blue */
    margin-bottom: 30px;
    font-weight: 700;
}

.service-info-container h2 {
    font-size: 28px;
    color: #3c7fc3; /* Theme's blue shade */
    margin-bottom: 25px;
    font-weight: 600;
}

.serviceList {
    text-align: left;
    margin: 0 auto 50px;
    max-width: 700px;
    padding: 0;
    list-style: none;
}

.serviceList li {
    font-size: 18px;
    color: #333;
    margin-bottom: 15px;
    position: relative;
    padding-left: 30px;
    line-height: 1.5; /* Improved readability */
}

.serviceList li::before {
    content: '✔';
    color: #3c7fc3; /* Icon color */
    position: absolute;
    left: 0;
    top: 0;
    font-size: 20px;
}

.pricing-section {
    background: linear-gradient(135deg, #4b536a, #6c7a89); /* Gradient for a more dynamic look */
    color: white;
    padding: 30px;
    margin-bottom: 50px;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effects */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Enhanced shadow for depth */
    position: relative;
    overflow: hidden;
}

.pricing-section h2 {
    margin-bottom: 10px;
}

.pricing-section p {
    font-size: 24px;
    font-weight: bold;
}

.pricing-section .original-price {
    font-size: 22px;
    color: #ff6b6b; /* Red color for original price */
    text-decoration: line-through; /* Strikethrough effect */
    margin-bottom: 5px;
}

.pricing-section .discount-end {
    font-size: 18px;
    font-style: italic; /* Italicize the discount end date */
    margin-top: 10px;
    opacity: 0.8; /* Slightly lighter to differentiate */
}

.pricing-section .countdown-timer {
    font-size: 22px;
    color: #ffdd57; /* Bright yellow to stand out */
    font-weight: bold;
    margin-top: 10px;
    border: 2px dashed #ff6b6b; /* Dashed border to draw attention */
    padding: 10px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1); /* Light overlay for visibility */
    box-shadow: 0 0 10px rgba(255, 107, 107, 0.2); /* Subtle shadow for emphasis */
}

.purchase-button, .back-button {
    padding: 15px 35px;
    font-size: 20px;
    border-radius: 50px;
    cursor: pointer;
    margin: 15px;
    border: none;
    font-weight: bold;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Box shadow for depth */
    transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth hover effect */
}

.purchase-button {
    background: linear-gradient(to right, #3c7fc3, #1e3c72); /* Blue gradient */
    color: white;
}

.purchase-button:hover {
    transform: scale(1.08);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    background: linear-gradient(to right, #1e3c72, #3c7fc3); /* Reverse gradient on hover */
}

.back-button {
    background: linear-gradient(to right, #e74c3c, #c0392b); /* Red gradient */
    color: white;
}

.back-button:hover {
    transform: scale(1.08);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    background: linear-gradient(to right, #c0392b, #e74c3c); /* Reverse red gradient on hover */
}
