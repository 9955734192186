.contact-info-container {
    width: 100%;
    margin-top: 50px;
    padding: 20px;
    background-color: #f7f9fc;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.contact-heading {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #333;
}

.contact-description {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #666;
}

.contact-details {
    list-style: none;
    padding: 0;
}

.contact-details li {
    margin: 10px 0;
    font-size: 1rem;
    color: #555;
}

.contact-details i {
    margin-right: 8px;
    color: #007bff;
}

.contact-details a {
    color: #007bff;
    text-decoration: none;
}

.contact-details a:hover {
    text-decoration: underline;
}
