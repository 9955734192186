/* OrderReviewPage.css */

.user-info {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;     /* Center vertically */
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
    text-align: center;      /* Ensure text is centered */
}

/* Styling for individual user info fields */
.user-name, .user-email {
    font-size: 16px;
    color: #555;
    font-weight: bold;
    margin: 0 10px;  /* Adds space between username and email */
}

.order-review-container {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 80px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

.order-review-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.order-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.order-table th, .order-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.order-table th {
    background-color: #f1f1f1;
    font-weight: bold;
    color: #333;
}

.product-info {
    display: flex;
    align-items: center;
}

.product-image {
    width: 60px;
    height: 60px;
    margin-right: 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.product-details p {
    margin: 0;
    color: #555;
}

.quantity-control {
    display: flex;
    align-items: center;
}

.quantity-button {
    width: 30px;
    height: 30px;
    border: none;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
}

.quantity-button:hover {
    background-color: #0056b3;
}

.quantity {
    margin: 0 10px;
    font-size: 16px;
}

.total-price {
    color: #333;
    font-weight: bold;
}

.remove-item {
    text-align: center;
}

.remove-button {
    background: none;
    border: none;
    color: #ff4d4f;
    font-size: 18px;
    cursor: pointer;
}

.remove-button:hover {
    color: #d11a2a;
}

.order-summary {
    text-align: right;
    font-size: 18px;
    color: #333;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #ddd;
}

.proceed-button {
    width: 100%;
    padding: 15px;
    background-color: #28a745;
    color: white;
    border: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
}

.proceed-button:hover {
    background-color: #218838;
}


.payment-option {
    display: flex;
    align-items: center; /* Aligns items vertically in the center */
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.payment-option:last-child {
    border-bottom: none;
}

.payment-label {
    display: flex;
    align-items: center; /* Align image and text in a row */
    font-size: 15px;
    margin-left: 8px; /* Space between radio button and label */
    font-weight: bold;
}

.payment-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px; /* Space between the icon and the text */
}

label {
    display: flex;
    align-items: center; /* Ensure the label itself is a flex container with centered alignment */
}

.coupon-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.coupon-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 16px;
}

.apply-coupon-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.apply-coupon-button:hover {
    background-color: #0056b3;
}

.error-message {
    color: #d11a2a;
    margin-top: 10px;
    font-size: 14px;
}


.payment-note {
    margin-top: 10px;
    font-size: 14px;
    color: #d11a2a;
    background-color: #f8f9fa;
    padding: 8px;
    border-radius: 4px;
    font-style: italic;
    text-align: center;
}
